import React, { useState } from 'react';
import './Letter.css';
import {
  Col, Button, Form, Row,
} from 'react-bootstrap';
import useInput from '../../shared/forms/useInput';
import { useAppContext, BrowserSizeEnum } from '../../shared/context/AppContextProvider';
import sendAnalyticsPageViewEvent from '../../shared/analytics/TagManagerHelper';
import { IChatMessage } from './ILetter';
import { FetchMethod } from '../../shared/http/Fetch';
import Spinner from '../../shared/Spinner/Spinner';

function SpinnerX() {
  return (
    <div style={{
      position: 'absolute', zIndex: 9999, marginLeft: '50%', marginTop: 100,
    }}
    >
      <Spinner />
    </div>
  );
}

// Hook-based form.  Ref blog: https://rangle.io/blog/simplifying-controlled-inputs-with-hooks/
export default function CustomerForm() {
  sendAnalyticsPageViewEvent('LetterWritter');

  const [lettercontent, setLettercontent] = useState('');
  const [fetching, setFetching] = useState(false);
  const { value: firstName, bind: bindFirstName, reset: resetFirstName } = useInput('');
  const { value: favorite, bind: bindFavorite, reset: resetFavorite } = useInput('');
  const { value: topic, bind: bindTopic, reset: resetTopic } = useInput('');
  const { fetchSecure, browserSize } = useAppContext();

  const chatMessages = new Array<IChatMessage>();
  chatMessages.push({ role: 'system', content: 'You are a letter writing assistant with a flair for creativity.' });

  const handleSubmit = async (evt: React.SyntheticEvent<EventTarget>) => {
    setFetching(true);
    evt.preventDefault();
    const content = `Write a welcome letter to a customer named ${firstName} who works with ${topic}.`
      + ` Say we are giving a seminar about ${topic}, and provide a short paragraph under 20 words that is professional sounding that will be discussed in the seminar.`
      + ` Then quote a movie line that uses the name ${firstName}.  Then write a cute poem about safety-first and use the name ${firstName}, the topic ${topic} and ${favorite} in the poem.`
      + " Finally, sign it as our company 'Valorem-Reply' and say we look forward to seeing you at the seminar."
      + ' Format the response as an HTML letter with a header, body, and footer.';
      // + ' Format the response as json like {greeting, seminar, moveiquote, poem, closing}';
    chatMessages.push({ role: 'user', content });

    const result = await fetchSecure(FetchMethod.post, '/api/Guru', chatMessages);
    if (result != null) {
      const json = await result.json();
      setLettercontent(json[json.length - 1].content);
    } else {
      setLettercontent('No letter content returned from server.');
    }

    resetFirstName();
    resetFavorite();
    resetTopic();
    setFetching(false);
    return result;
  };

  const formcontent = () => {
    if (browserSize === BrowserSizeEnum.large) {
      return (
        <>
          <Row>
            <Form.Group as={Col} controlId="firstname">
              <Form.Label>Customer&apos;s Name</Form.Label>
              <Form.Control type="text" placeholder="First Name" {...bindFirstName} />
            </Form.Group>
            <Form.Group as={Col} controlId="lastname">
              <Form.Label>Something the customer likes</Form.Label>
              <Form.Control type="text" placeholder="Favorite thing" {...bindFavorite} />
            </Form.Group>
            <Form.Group as={Col} controlId="topic">
              <Form.Label>Seminar Topic</Form.Label>
              <Form.Control type="text" placeholder="Seminar Topic" {...bindTopic} />
            </Form.Group>
          </Row>
          <Row style={{ margin: 20 }}>
            <Col sm={{ span: 10, offset: 10 }}>
              <Button id="submitbutton" variant="primary" type="submit">Create Invitation</Button>
            </Col>
          </Row>
          <Row>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: lettercontent }} />
            {/* {lettercontent} */}
            {/* <pre>{lettercontent}</pre> */}
          </Row>
        </>
      );
    }
    return (
      <>
        <Form.Group as={Col} controlId="firstname">
          <Form.Label>First Name</Form.Label>
          <Form.Control type="text" placeholder="First Name" {...bindFirstName} />
        </Form.Group>
        <Form.Group as={Col} controlId="favoritething">
          <Form.Label>Something the customer likes</Form.Label>
          <Form.Control type="text" placeholder="Favorite thing" {...bindFavorite} />
        </Form.Group>
        <Form.Group as={Col} controlId="topic">
          <Form.Label>Seminar Topic</Form.Label>
          <Form.Control type="text" placeholder="Seminar Topic" {...bindTopic} />
        </Form.Group>
        <Col sm={{ span: 10, offset: 10 }}>
          <Button id="submitbutton" variant="primary" type="submit">Create Invitation</Button>
        </Col>
        <Col>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: lettercontent }} />
        </Col>
      </>
    );
  };

  return (
    <>
      <h3>Create a Letter</h3>
      {fetching === true ? <SpinnerX /> : null}
      <Form onSubmit={handleSubmit}>
        {formcontent()}
      </Form>
    </>
  );
}
