/* eslint-disable no-console */
import {
  AuthenticationResult, BrowserAuthError, InteractionRequiredAuthError, IPublicClientApplication,
} from '@azure/msal-browser';
import { IAppConfig } from '../context/IAppConfig';

export enum FetchMethod {
  get = 'GET',
  post = 'POST',
}

export const getToken = async (msalClient:IPublicClientApplication, appConfig:IAppConfig): Promise<AuthenticationResult | undefined> => {
  const accounts = msalClient.getAllAccounts();
  let tokenresponse: AuthenticationResult | null = null;
  try {
    console.log('getting token');
    // Silently acquires an access token which is then attached to a request for MS Graph data
    tokenresponse = await msalClient.acquireTokenSilent({
      ...appConfig.loginRequest,
      account: accounts[0],
    });
    console.log(`Fetch getToken Token Acquired = ${tokenresponse.accessToken}`);
    return tokenresponse;
  } catch (err) {
    console.error('Fetch.ts caught error while invoking acquireTokenSilent:');
    console.error(err);
    if (err instanceof InteractionRequiredAuthError || err instanceof BrowserAuthError) {
      console.log('since the acquireTokenSilent error is an InteractionRequiredAuthError, app will try using acquirTokenPopup... Hopefully the browser does not block the popup');
      try {
        tokenresponse = await msalClient.acquireTokenPopup({
          ...appConfig.loginRequest,
          account: accounts[0],
        });
        return tokenresponse;
      } catch (errPopup) {
        console.error('Fetch.ts getToken caught error while invoking acquireTokenPopup:');
        console.error(errPopup);
        try {
          console.warn('now trying loginPopup');
          await msalClient.loginPopup(appConfig.loginRequest);
          try {
            tokenresponse = await msalClient.acquireTokenSilent({
              ...appConfig.loginRequest,
              account: accounts[0],
            });
            console.log(`Fetch getToken Token Acquired = ${tokenresponse.accessToken}`);
            return tokenresponse;
          } catch (errorAcquireToken) {
            console.error(`AcquireTokenSilent after loginPopup failed with eror=${errorAcquireToken}`);
            throw new Error(`AcquireTokenSilent after loginPopup failed with eror=${errorAcquireToken}`);
          }
        } catch (errLogin) {
          console.error('Fetchtry.ts caught error while invoking loginPopup:');
          console.error(errLogin);
          throw new Error('Unable to get token from msal-browser, user must sign out and sign back in.');
        }
      }
    }
    throw new Error('Fetch.getToken failed to get token');
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchSecure = async (msalClient:IPublicClientApplication, appConfig:IAppConfig, method: FetchMethod, relativeEndpoint: string, body?: any): Promise<Response | null> => {
  console.log(`fetchSecure for ${relativeEndpoint}`);

  const endpoint = appConfig.apiEndpoint + relativeEndpoint;
  const token = await getToken(msalClient, appConfig);
  if (token == null) {
    throw new Error('Your RefreshToken is expired, and your login session is expired.  Please Sign-out and Sign back in');
  }
  const headers = new Headers();
  const bearer = `Bearer ${token.accessToken}`;
  headers.append('Authorization', bearer);
  headers.append('Content-Type', 'application/json');
  headers.append('Ocp-Apim-Subscription-Key', `${appConfig.ocpApimSubscriptionKey}`);
  // headers.append('credentials', 'include');

  const options = method === FetchMethod.get
    ? {
      method: 'GET',
      headers,
    }
    : {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };

  console.log('Calling Web API...');

  const response = await fetch(endpoint, options)
    .then((thenResponse) => thenResponse)
    .catch((error) => {
      console.error(error);
      throw error;
    });

  return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchUnsecure = async (appConfig:IAppConfig, method: FetchMethod, relativeEndpoint: string, body?: any): Promise<Response | null> => {
  console.log(`fetchUnsecure for ${relativeEndpoint}`);

  const endpoint = appConfig.apiEndpoint + relativeEndpoint;

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const options = method === FetchMethod.get
    ? {
      method: 'GET',
      headers,
    }
    : {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };

  console.log('Calling Web API...');

  const response = await fetch(endpoint, options)
    .then((thenResponse) => thenResponse)
    .catch((error) => {
      console.error(error);
      throw error;
    });

  return response;
};
