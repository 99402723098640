import Dexie, { Table } from 'dexie';
import { IPerson, IPeopleSync } from './IPerson';

/// Dexie Turorial
/// https://dexie.org/docs/Tutorial/React
///
export class CustomerDB extends Dexie {
  public customers!: Table<IPerson, number>;

  public peoplesync!: Table<IPeopleSync, number>;

  public constructor() {
    super('CustomerDB');
    this.version(1).stores({
      customers: '++id,firstname,lastname,classcodeid, databaseId, needsync',
      peoplesync: '++id,peopleid,lastname',
    });
  }

  public async addCustomer(customer: IPerson): Promise<void> {
    return this.transaction('rw', this.customers, this.peoplesync, async () => {
      const customerId = await this.customers.add({ ...customer, needsync: true });
      await this.peoplesync.add({ peopleid: customerId, lastname: customer.lastname });
    });
  }

  public async getAllCustomers(): Promise<Array<IPerson>> {
    const result = this.customers.toArray();
    return result;
  }

  public async getCustomerById(customerId: number): Promise<IPerson | undefined> {
    const cust = await this.customers.where('id').equals(customerId).first();
    return cust;
  }

  public async customersNeedSyncd(): Promise<Array<IPerson>> {
    // const needsyncd = this.customers.where("needsync").equals(true).toArray()
    // <-- dexie can't use bools as index column :(
    // we won't have a large number of persons so scan them all with filter.
    // If we did need to scale up then use an indexed column such as needsync as a number
    // where 0==false
    const needsyncd: Array<IPerson> = [];
    await this.customers.filter((c) => c.needsync).each((cust) => {
      needsyncd.push(cust);
    });
    return needsyncd;
  }

  public async setSyncStatus(customerId: number): Promise<void> {
    const customer: IPerson | undefined = await this.customers.where('id').equals(customerId).first();
    if (customer !== undefined) {
      await this.customers.update(customerId, {
        needsync: false,
      });
    }
  }
}

export const customerDb = new CustomerDB();
