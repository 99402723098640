import React, { useState } from 'react';
import { AuthenticationResult } from '@azure/msal-browser';
import './BlobFile.css';
import {
  Button, Form,
} from 'react-bootstrap';
import saveAs from 'file-saver';
import { useAppContext, BrowserSizeEnum } from '../../shared/context/AppContextProvider';
import useInput from '../../shared/forms/useInput';
import sendAnalyticsPageViewEvent from '../../shared/analytics/TagManagerHelper';
import Spinner from '../../shared/Spinner/Spinner';
import { FetchMethod } from '../../shared/http/Fetch';

function SpinnerX() {
  return (
    <div style={{
      position: 'absolute', zIndex: 9999, marginLeft: '50%', marginTop: 100,
    }}
    >
      <Spinner />
    </div>
  );
}

export default function BlobFileForm() {
  sendAnalyticsPageViewEvent('BlobFile');

  const [fetching, setFetching] = useState(false);
  const [uploadResult, setUploadResult] = useState('');
  const { value: tag, bind: bindTag } = useInput('');
  // const { value: filename, bind: bindFilename } = useInput('CoPilot_WritingUnitTests.txt');
  const [filename, setFilename] = useState('');
  const { value: containername, bind: bindContainername } = useInput('macblobfile');
  const { value: programname, bind: bindProgramname } = useInput('MSSP');
  const { value: workshopname, bind: bindWorkshopname } = useInput('RFP');
  const { value: filetype, bind: bindFiletype } = useInput('FAQ');
  // const [formCount, setFormCount] = useState(0);

  const {
    appConfig,
    browserSize,
    showToastMessage,
    getToken,
    fetchSecure,
  } = useAppContext();

  const fileSected = () => {
    setUploadResult('');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUploadSubmit = async (evt: React.BaseSyntheticEvent) => {
    evt.preventDefault();
    setFetching(true);
    const tokenresponse: AuthenticationResult | undefined = await getToken();
    // const formData = new FormData();
    // const file = evt.target;
    // formData.append('file', file as File);

    // const formData = evt.target;
    const formElement = document.getElementById('mainform') as HTMLFormElement;
    // if (formElement === null) {
    //   showToastMessage('Unable to get form element');
    //   return;
    // }
    const formData = new FormData(formElement);
    const file = evt.target[0].files[0];
    // formData.append('containername', containername);
    // formData.append('programname', programname);
    // formData.append('workshopname', workshopname);
    // formData.append('filetype', filetype);
    formData.append('file', file as File);

    if (tokenresponse === undefined) {
      showToastMessage('Unable to get an access token');
      return;
    }
    // const endpoint = `${appConfig.apiEndpoint}/api/BlobFile/UploadFile?programname=${programname}&workshopname=${workshopname}&filetype=${filetype}&containername=${containername}`;
    const endpoint = `${appConfig.apiEndpoint}/api/BlobFile/UploadFile`;
    const bearer = `Bearer ${tokenresponse.accessToken}`;
    const headers = new Headers();
    headers.append('Authorization', bearer);
    // headers.append('Content-Type', 'multipart/form-data');
    headers.append('Ocp-Apim-Subscription-Key', `${appConfig.ocpApimSubscriptionKey}`);
    const options = {
      method: 'POST',
      headers,
      body: formData,
    };
    const result = await fetch(endpoint, options)
      .then(async (response) => {
        console.log(response);
        // const data = await response.json();
        // return data.content;
      })
      .catch((error) => {
        showToastMessage(`BlobFile UPload failed with error:${error}`);
        return 'ERROR';
      });
    setFetching(false);
    if (result !== null) {
      setUploadResult('');
    }
  };

  const completefilename = () => `${programname}_${workshopname}_${filetype}`;

  const getDownloadFile = async (evt: React.BaseSyntheticEvent) => {
    setFetching(true);
    evt.preventDefault();
    const tokenresponse: AuthenticationResult | undefined = await getToken();
    const formData = new FormData();
    setFilename(`${programname}_${workshopname}_${filetype}`);
    formData.append('filename', filename);
    formData.append('tag', tag);
    formData.append('containername', containername);

    if (tokenresponse === undefined) {
      showToastMessage('Unable to get an access token');
      throw Error('unable to get token');
    }
    // const endpoint = `${appConfig.apiEndpoint}/api/BlobFile/DownloadFile`;
    // // const bearer = `Bearer ${tokenresponse.accessToken}`;
    // const headers = new Headers();
    // // headers.append('Authorization', bearer);
    // // headers.append('Content-Type', 'multipart/form-data')
    // headers.append('Content-type', 'application/json');
    // headers.append('Ocp-Apim-Subscription-Key', `${appConfig.ocpApimSubscriptionKey}`);
    // const body = {
    //   filename,
    // };
    // const options = {
    //   method: 'GET',
    //   headers,
    //   body: JSON.stringify(body),
    //   responseType: 'blob',
    // };

    const response = await fetchSecure(FetchMethod.get, `/api/BlobFile/DownloadFile?filename=${filename}&containername=${containername}`);
    if (response != null) {
      response.blob()
        .then((data) => {
          saveAs(data, filename);
        });
    }

    setFetching(false);
    // return mike;
    // const download = await fetch(endpoint, options)
    //   .then((response) => response.blob());
    // return download;
  };

  const formcontent = () => {
    if (browserSize === BrowserSizeEnum.large) {
      return (
        <>
          <Form.Group controlId="fileuploadForm">
            <Form.Label>File Upload</Form.Label>
            <Form.Control type="file" onChange={fileSected} />
          </Form.Group>
          <Form.Group style={{ marginTop: '100px' }}>
            <Form.Control type="text" placeholder="Program" name="programname" {...bindProgramname} />
            <Form.Control type="text" placeholder="Workshop" name="workshopname" {...bindWorkshopname} />
            <Form.Control type="text" placeholder="File type" name="filetype" {...bindFiletype} />
            {/* <Form.Control type="text" placeholder="Tag" name="tag" {...bindTag} /> */}
            <Form.Control type="text" placeholder="Container name" name="containername" {...bindContainername} />
            <div>{completefilename()}</div>
          </Form.Group>
          <Button variant="primary" type="submit" style={{ margin: 20 }}>Upload File</Button>
          <div>
            { uploadResult !== '' ? <h4>Upload Result</h4> : null }
            <output form="uploadForm" name="upload">{uploadResult}</output>
          </div>
        </>
      );
    }
    return (
      <>
        <Form.Group controlId="fileuploadForm">
          <Form.Label style={{ margin: '5em' }}>File Upload</Form.Label>
          <Form.Control type="file" onChange={fileSected} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Tag</Form.Label>
          <Form.Control type="text" placeholder="Tag" name="tag" {...bindTag} />
        </Form.Group>
        <Button variant="primary" type="submit" disabled style={{ margin: 20 }}>Submit</Button>
      </>
    );
  };

  const downloadForm = () => {
    if (browserSize === BrowserSizeEnum.large) {
      return (
        <Form id="downloadform" encType="application/json" onSubmit={getDownloadFile}>
          <Form.Group style={{ marginTop: '100px' }}>
            <Form.Label>Download a file</Form.Label>
            {/* <Form.Control type="text" placeholder="Filename to download" {...bindFilename} /> */}
            <Form.Control type="text" placeholder="Program" name="programname" {...bindProgramname} />
            <Form.Control type="text" placeholder="Workshop" name="workshopname" {...bindWorkshopname} />
            <Form.Control type="text" placeholder="File type" name="filetype" {...bindFiletype} />
          </Form.Group>
          {/* <Button variant="primary" type="submit" style={{ margin: 20 }} onClick={getDownloadFile}>Download</Button> */}
          <Button variant="primary" type="submit" style={{ margin: 20 }}>Download</Button>
        </Form>
      );
    }
    return (
      <Form>
        <Form.Group style={{ marginTop: '100px' }}>
          <Form.Label>Download a file</Form.Label>
        </Form.Group>
        <Button variant="primary" type="submit" style={{ margin: 20 }} onClick={getDownloadFile}>Download File</Button>
      </Form>
    );
  };

  return (
    <>
      <h3>Blob File Upload</h3>
      <h4 style={{ textAlign: 'center', color: 'blue' }}>Upload a file into blob storage</h4>
      {fetching === true ? <SpinnerX /> : null}
      <Form id="mainform" encType="multipart/form-data" onSubmit={handleUploadSubmit}>
        {formcontent()}
      </Form>
      {downloadForm()}
    </>
  );
}
