import React, { useEffect, useReducer, useState } from 'react';
import { AuthenticationResult } from '@azure/msal-browser';
import './FormRecognizerForm.css';
import {
  Button, Card, Form,
} from 'react-bootstrap';
import { useAppContext, BrowserSizeEnum } from '../../shared/context/AppContextProvider';
import useInput from '../../shared/forms/useInput';
import sendAnalyticsPageViewEvent from '../../shared/analytics/TagManagerHelper';
import Spinner from '../../shared/Spinner/Spinner';
import { IHrChatResponse, IHrChatHistory } from './IFormRecognizerForm';
import { FetchMethod } from '../../shared/http/Fetch';

function SpinnerX() {
  return (
    <div style={{
      position: 'absolute', zIndex: 9999, marginLeft: '50%', marginTop: 100,
    }}
    >
      <Spinner />
    </div>
  );
}

export default function FormRecognizerForm() {
  sendAnalyticsPageViewEvent('RecognizerForm');

  const [fetching, setFetching] = useState(false);
  const [uploadResult, setUploadResult] = useState('');
  const { bind: bindIndexName } = useInput('prebuilt-read');
  const { value: question, bind: bindQuestion, reset: resetQuestion } = useInput('');
  const [chatCount, setChatCount] = useState(0);
  function chatHistoryReducer(state: Array<IHrChatHistory>, action: { type: string, payload: IHrChatHistory | null }) {
    switch (action.type) {
      case 'reset':
        return [];
      case 'add':
        return action.payload !== null ? state.concat(action.payload) : state; // or return [...state, action.payload];
      case 'replace_last':
        return action.payload != null ? state.slice(0, state.length - 1).concat(action.payload) : state;
      default:
        throw new Error();
    }
  }
  const [chatHistory, chatDispatch] = useReducer(chatHistoryReducer, []);

  const {
    appConfig,
    browserSize,
    showToastMessage,
    getToken,
    fetchSecure,
  } = useAppContext();

  const fileSected = () => {
    setUploadResult('');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUploadSubmit = async (evt: React.BaseSyntheticEvent) => {
    evt.preventDefault();
    setFetching(true);
    // const formData = new FormData();
    // const file = evt.target;
    // formData.append('file', file as File);

    // const formData = evt.target;
    const formElement = document.getElementById('mainform') as HTMLFormElement;
    // if (formElement === null) {
    //   showToastMessage('Unable to get form element');
    //   return;
    // }
    const formData = new FormData(formElement);
    const file = evt.target[0].files[0];
    formData.append('file', file as File);

    const tokenresponse: AuthenticationResult | undefined = await getToken();
    if (tokenresponse === undefined) {
      showToastMessage('Unable to get an access token');
      return;
    }
    const endpoint = `${appConfig.apiEndpoint}/api/TextExtractor/UploadFile`;
    const bearer = `Bearer ${tokenresponse.accessToken}`;
    const headers = new Headers();
    headers.append('Authorization', bearer);
    // headers.append('Content-Type', 'multipart/form-data');
    headers.append('Ocp-Apim-Subscription-Key', `${appConfig.ocpApimSubscriptionKey}`);
    const options = {
      method: 'POST',
      headers,
      body: formData,
    };
    const result = await fetch(endpoint, options)
      .then(async (response) => {
        // const data = await response.json();
        // return data.content;
        const data = await response.text();
        return data;
      })
      .catch((error) => {
        showToastMessage(`Fetching Text Sentiment failed with error:${error}`);
        return 'ERROR';
      });
    setFetching(false);
    if (result !== null) {
      setUploadResult(result);
    }
  };
  useEffect(() => {
    async function fetchChat() {
      if (chatHistory.length > 0) {
        setFetching(true);
        const chatRequest = {
          approach: 'rrr',
          history: chatHistory,
          overrides: {
            semantic_captions: false,
            semantic_ranker: false,
            suggest_followup_questions: true,
            top: 1,
          },
        };
        const result = await fetchSecure(FetchMethod.post, '/api/AiRag/chat', chatRequest);
        if (result != null) {
          const json = await result.json() as IHrChatResponse;
          chatDispatch({ type: 'replace_last', payload: { user: question, bot: json.answer } });
          resetQuestion();
        } else {
          showToastMessage('Error in chatbot response');
        }
        setFetching(false);
      }
    }
    fetchChat();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatCount]);

  const submitQuestion = async (evt: React.SyntheticEvent<EventTarget>) => {
    evt.preventDefault();
    chatDispatch({ type: 'add', payload: { user: question } });
    setChatCount(chatCount + 1);
  };

  const formcontent = () => {
    if (browserSize === BrowserSizeEnum.large) {
      return (
        <>
          <Form.Group controlId="fileuploadForm">
            <Form.Label>File Upload</Form.Label>
            <Form.Control type="file" onChange={fileSected} />
          </Form.Group>
          <Form.Group style={{ marginTop: '100px' }}>
            <Form.Label>Index Name</Form.Label>
            <Form.Control type="text" placeholder="Model Name" name="model" {...bindIndexName} />
          </Form.Group>
          <Button variant="primary" type="submit" style={{ margin: 20 }}>Upload File</Button>
          <div>
            { uploadResult !== '' ? <h4>Upload Result</h4> : null }
            <output form="uploadForm" name="upload">{uploadResult}</output>
          </div>
        </>
      );
    }
    return (
      <>
        <Form.Group controlId="fileuploadForm">
          <Form.Label style={{ margin: '5em' }}>File Upload</Form.Label>
          <Form.Control type="file" onChange={fileSected} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Index Name</Form.Label>
          <Form.Control type="text" placeholder="Last Name" {...bindIndexName} />
        </Form.Group>
        <Button variant="primary" type="submit" disabled style={{ margin: 20 }}>Submit</Button>
        <div>
          <h4>Sentiment</h4>
          <output form="uploadForm" name="sentiment">{uploadResult}</output>
        </div>
      </>
    );
  };

  const chatForm = () => {
    if (browserSize === BrowserSizeEnum.large) {
      return (
        <>
          <Form>
            <Form.Group style={{ marginTop: '100px' }}>
              <Form.Label>Extract Text and Get Summary for Variety of Documents</Form.Label>
              <Form.Control type="text" placeholder="Type a new question (e.g. what holidays are there?)" {...bindQuestion} />
            </Form.Group>
            <Button variant="primary" type="submit" style={{ margin: 20 }} onClick={submitQuestion}>Ask</Button>
          </Form>
          <div>Responses</div>
        </>
      );
    }
    return (
      <>
        <Form>
          <Form.Group style={{ marginTop: '100px' }}>
            <Form.Label>Extract Text and Get Summary for MS Office Documents</Form.Label>
            <Form.Control type="text" placeholder="Type a new question (e.g. what holidays are there?)" {...bindQuestion} />
          </Form.Group>
          <Button variant="primary" type="submit" style={{ margin: 20 }} onClick={submitQuestion}>Ask</Button>
        </Form>
        <div>Responses</div>
      </>
    );
  };

  const renderChatCards = () => {
    let i = 0;
    const cards = chatHistory
      .map((history) => {
        i += 1;
        return (
          <>
            <Card key={i} style={{ width: '60%', alignContent: 'flex-start' }}>
              <Card.Body>
                <Card.Title>User</Card.Title>
                <Card.Text>
                  {history.user}
                </Card.Text>
              </Card.Body>
            </Card>
            <Card key={i} style={{ width: '60%', alignContent: 'flex-end' }}>
              <Card.Body>
                <Card.Title>User</Card.Title>
                <Card.Text>
                  {history.bot}
                </Card.Text>
              </Card.Body>
            </Card>
          </>
        );
      });
    return cards;
  };

  return (
    <>
      <h3>Form Recognizer Text Extraction</h3>
      <h4 style={{ textAlign: 'center', color: 'blue' }}>Upload File and get back Extracted Text and Summary</h4>
      <div>Supports CSV, HTML, JSON, KML, MS Office (incl Power Point), Open Doc, PDF, Plat Text, RTF, XML and ZIP</div>
      {fetching === true ? <SpinnerX /> : null}
      <Form id="mainform" encType="multipart/form-data" onSubmit={handleUploadSubmit}>
        {formcontent()}
      </Form>
      {renderChatCards()}
      {chatForm()}
    </>
  );
}
