import React from 'react';
import {
  BrowserRouter as Router, Route, Switch, Link,
} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { useIsAuthenticated } from '@azure/msal-react';
import SignInButton from '../forms/SignInButton';
import SignOutButton from '../forms/SignOutButton';
import { PrivateRoute } from './PrivateRoute';
import Home from '../../app/Home/Home';
import ChatForm from '../../app/Chat/ChatForm';
import Events from '../../app/Events/Events';
import LetterForm from '../../app/Letter/LetterForm';
// import FileSummary from '../../app/FileSummary/FileSummary';
import FileSummary2 from '../../app/FileSummary2/FileSummary2';
import CustomerList2 from '../../app/Customer2/CustomerList2';
import HrChatForm from '../../app/HrChat/HrChatForm';
import FormRecognizerForm from '../../app/FormRecognizerForm/FormRecognizerForm';
import BlobFileForm from '../../app/BlobFile/BlobFileForm';
import { WeatherForecast } from '../../app/WeatherForecast/WeatherForecast';
// import FacebookPrivacy from '../../app/FacebookPrivacy/FacebookPrivacy';
// import FacebookFeed from '../../app/FacebookFeed/FacebookFeed';

export default function Routes() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Router>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">Events HOME</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto" navbarScroll>

              <Nav.Item eventkey="1" href="/Events">
                <Nav.Link as={Link} to="/Events">  Events </Nav.Link>
              </Nav.Item>

              <Nav.Item eventkey="2" href="/weatherforecast">
                <Nav.Link as={Link} to="/weatherforecast">  Weather Forecast </Nav.Link>
              </Nav.Item>

              <Nav.Item eventkey="3" href="/letterform">
                <Nav.Link as={Link} to="/letterform">  Letters </Nav.Link>
              </Nav.Item>

              <Nav.Item eventkey="4" href="/chatform">
                <Nav.Link as={Link} to="/chatform">  Q & A </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item eventkey="5" href="/sentiment">
                <Nav.Link as={Link} to="/sentiment">  File Sentiment</Nav.Link>
              </Nav.Item> */}

              <Nav.Item eventkey="5" href="/sentiment2">
                <Nav.Link as={Link} to="/sentiment2">  File Summary</Nav.Link>
              </Nav.Item>

              <Nav.Item eventkey="6" href="/customerlist2">
                <Nav.Link as={Link} to="/customerlist2">  Customers </Nav.Link>
              </Nav.Item>

              <Nav.Item eventkey="7" href="/hrdocs">
                <Nav.Link as={Link} to="/hrdocs">  HR Docs </Nav.Link>
              </Nav.Item>

              <Nav.Item eventkey="7" href="/formrec">
                <Nav.Link as={Link} to="/formrec">  Form Recognizer </Nav.Link>
              </Nav.Item>

              <Nav.Item eventkey="8" href="/blobfile">
                <Nav.Link as={Link} to="/blobfile">  Blob Files </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item eventkey="8" href="/FacebookPrivacy">
                <Nav.Link as={Link} to="/FacebookPrivacy">  FacebookPrivacy </Nav.Link>
              </Nav.Item> */}
              {/* <Nav.Item eventkey="8" href="/facebookfeed">
                <Nav.Link as={Link} to="/facebookfeed">  Facebook Feed </Nav.Link>
              </Nav.Item> */}

            </Nav>
            <Nav>
              <div className="d-flex">
                {isAuthenticated ? <SignOutButton /> : <SignInButton />}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/Events"
          component={Events}
        />
        <Route
          exact
          path="/letterform"
          component={LetterForm}
        />
        <Route
          exact
          path="/chatform"
          component={ChatForm}
        />
        {/* <Route
          exact
          path="/sentiment"
          component={FileSummary}
        /> */}
        <Route
          exact
          path="/sentiment2"
          component={FileSummary2}
        />
        <Route
          exact
          path="/customerlist2"
          component={CustomerList2}
        />
        <Route
          exact
          path="/hrdocs"
          component={HrChatForm}
        />
        <Route
          exact
          path="/formrec"
          component={FormRecognizerForm}
        />
        <Route
          exact
          path="/blobfile"
          component={BlobFileForm}
        />
        <PrivateRoute
          exact
          path="/weatherforecast"
          component={WeatherForecast}
        />
        {/* <Route
          exact
          path="/FacebookPrivacy"
          component={FacebookPrivacy}
        />
        <Route
          exact
          path="/facebookfeed"
          component={FacebookFeed}
        /> */}
      </Switch>
    </Router>
  );
}
