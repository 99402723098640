import React, { useState, useReducer, useEffect } from 'react';
import './ChatForm.css';
import {
  Col, Button, Form, Row, Card,
} from 'react-bootstrap';
import useInput from '../../shared/forms/useInput';
import { useAppContext, BrowserSizeEnum } from '../../shared/context/AppContextProvider';
import sendAnalyticsPageViewEvent from '../../shared/analytics/TagManagerHelper';
import { IChatMessage } from './IChatMessage';
import { FetchMethod } from '../../shared/http/Fetch';
import Spinner from '../../shared/Spinner/Spinner';

function SpinnerX() {
  return (
    <div style={{
      position: 'absolute', zIndex: 9999, marginLeft: '50%', marginTop: 100,
    }}
    >
      <Spinner />
    </div>
  );
}

// Hook-based form.  Ref blog: https://rangle.io/blog/simplifying-controlled-inputs-with-hooks/
export default function ChatForm() {
  sendAnalyticsPageViewEvent('ChatForm');

  const [fetching, setFetching] = useState(false);
  const [chatCount, setChatCount] = useState(0);
  const { value: question, bind: bindQuestion, reset: resetQuestion } = useInput('');
  const { fetchSecure, browserSize, showToastMessage } = useAppContext();

  function chatMessageReducer(state: Array<IChatMessage>, action: { type: string, payload: IChatMessage | null }) {
    switch (action.type) {
      case 'reset':
        return [];
      case 'add':
        return action.payload !== null ? state.concat(action.payload) : state; // or return [...state, action.payload];
      default:
        throw new Error();
    }
  }
  const [chatMessages, chatDispatch] = useReducer(chatMessageReducer, []);

  useEffect(() => {
    chatDispatch({ type: 'reset', payload: null });
    chatDispatch({ type: 'add', payload: { role: 'system', content: 'You are a scholar with a wide range of expertise; you know everything, and you lke to answer questions.' } });
  }, []);

  useEffect(() => {
    async function fetchChat() {
      if (chatMessages.length > 1) {
        setFetching(true);
        const result = await fetchSecure(FetchMethod.post, '/api/Guru', chatMessages);
        if (result != null) {
          const json = await result.json();
          const answer = json[json.length - 1].content;
          chatDispatch({ type: 'add', payload: { role: 'assistant', content: answer } });
        } else {
          showToastMessage('Error in chatbot response');
        }
        setFetching(false);
      }
    }
    fetchChat();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatCount]);

  const handleSubmit = async (evt: React.SyntheticEvent<EventTarget>) => {
    evt.preventDefault();
    chatDispatch({ type: 'add', payload: { role: 'user', content: question } });
    resetQuestion();
    setChatCount(chatCount + 1);
  };

  const renderCharCards = () => {
    let i = 0;
    const cards = chatMessages
      // .filter(() => {
      //   i += 1;
      //   if (i === 1) {
      //     return false;
      //   }
      //   return true;
      // })
      .map((message) => {
        i += 1;
        if (message.role === 'user') {
          return (
            <Card key={i} style={{ width: '60%', alignContent: 'flex-start' }}>
              <Card.Body>
                <Card.Title>User</Card.Title>
                <Card.Text>
                  {message.content}
                </Card.Text>
              </Card.Body>
            </Card>

          );
        }
        return (
          <Card key={i} style={{ width: '60%', alignContent: 'flex-end' }}>
            <Card.Body>
              <Card.Title>System</Card.Title>
              <Card.Text>
                {message.content}
              </Card.Text>
            </Card.Body>
          </Card>
        );
      });
    return cards;
  };

  const formcontent = () => {
    if (browserSize === BrowserSizeEnum.large) {
      return (
        <>
          <Row>
            <Form.Group as={Col} controlId="question">
              <Form.Label>Question</Form.Label>
              <Form.Control type="text" placeholder="Question" {...bindQuestion} />
            </Form.Group>
          </Row>
          <Row style={{ margin: 20 }}>
            <Col sm={{ span: 10, offset: 10 }}>
              <Button id="submitbutton" variant="primary" type="submit">Submit Question</Button>
            </Col>
          </Row>
          <Row>
            {renderCharCards()}
          </Row>
        </>
      );
    }
    return (
      <>
        <Form.Group as={Col} controlId="question">
          <Form.Label>Question</Form.Label>
          <Form.Control type="text" placeholder="Question" {...bindQuestion} />
        </Form.Group>
        <Col sm={{ span: 10, offset: 10 }}>
          <Button id="submitbutton" variant="primary" type="submit">Create Invitation</Button>
        </Col>
        <Col>
          {renderCharCards()}
        </Col>
      </>
    );
  };

  return (
    <>
      <h3>Chat with Guru</h3>
      {fetching === true ? <SpinnerX /> : null}
      <Form onSubmit={handleSubmit}>
        {formcontent()}
      </Form>
    </>
  );
}
