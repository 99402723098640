import React, { useState, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { AuthenticationResult, SilentRequest } from '@azure/msal-browser';
import { useAppContext } from '../../shared/context/AppContextProvider';
import { IWeatherForecast } from './IWeatherForecast';

export function WeatherForecast() {
  const { appConfig, showToastMessage, getToken } = useAppContext();
  const { instance, accounts } = useMsal();
  const [weather, setWeather] = useState<Array<IWeatherForecast>>();
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    async function getWeather() {
      if (isAuthenticated) {
        console.log('WeatherForecast.tsx is calling secured api)');
        try {
          const response = await GetWeatherData();
          setWeather(response);
        } catch (err) {
          showToastMessage(`Error while fetching Weather Forecast: ${err}`);
        }
      }
    }
    getWeather();
  }, [isAuthenticated]);

  const GetWeatherData = async (): Promise<Array<IWeatherForecast>> => {
    const request = {
      ...appConfig.loginRequest,
      account: accounts[0],
    } as SilentRequest;
    // Silently acquires an access token which is then attached to a request for MS Graph data
    //const tokenresponse: AuthenticationResult = await instance.acquireTokenSilent(request);
    const tokenresponse: AuthenticationResult | undefined= await getToken();
    if (tokenresponse === undefined) {
      showToastMessage("Unable to get an access token");
      return [];
    }
    const endpoint = `${appConfig.apiEndpoint}/api/weatherforecast`;
    const bearer = `Bearer ${tokenresponse.accessToken}`;
    const headers = new Headers();
    headers.append('Authorization', bearer);
    headers.append('Ocp-Apim-Subscription-Key', `${appConfig.ocpApimSubscriptionKey}`);
    const options = {
      method: 'GET',
      headers,
    };
    return fetch(endpoint, options)
      .then((response) => response.json())
      .catch((error) => showToastMessage(`Fetching weather forecast failed with error:${error}`));
  };

  if (!weather) {
    return (
      <div>No Weather available, hang on while we fetch your forecast!!</div>
    );
  }

  const listItems = weather.map((w, i) => (
    <tr key={i}>
      <td style={{ border: '1px solid black' }}>{new Date(w.date).toDateString()}</td>
      <td style={{ border: '1px solid black' }}>{w.summary}</td>
    </tr>
  ));

  return (
    <>
      {!isAuthenticated
        && (
        <div style={{ margin: 'auto', border: '2px solid blue' }}>
          LOADING
        </div>
        )}
      <div style={{ margin: 'auto', border: '1px solid red' }}>
        This is a Private page
      </div>

      <div style={{ margin: 'auto' }}>
        <table style={{ border: '1px solid black', margin: 'auto' }}>
          <tbody>
            {listItems}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default WeatherForecast;
