import React, { useState } from 'react';
import { AuthenticationResult } from '@azure/msal-browser';
import './FileSummary.css';
import {
  Button, Form,
} from 'react-bootstrap';
import { useAppContext, BrowserSizeEnum } from '../../shared/context/AppContextProvider';
import sendAnalyticsPageViewEvent from '../../shared/analytics/TagManagerHelper';
import Spinner from '../../shared/Spinner/Spinner';

function SpinnerX() {
  return (
    <div style={{
      position: 'absolute', zIndex: 9999, marginLeft: '50%', marginTop: 100,
    }}
    >
      <Spinner />
    </div>
  );
}

export default function FileSummary2() {
  sendAnalyticsPageViewEvent('FileSummary2');

  const [fetching, setFetching] = useState(false);
  const [uploadResult, setUploadResult] = useState('');
  const [summaryResult, setSummaryResult] = useState('');
  const {
    appConfig,
    browserSize,
    showToastMessage,
    getToken,
  } = useAppContext();

  const fileSected = () => {
    setUploadResult('');
    setSummaryResult('');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (evt: React.BaseSyntheticEvent) => {
    evt.preventDefault();
    setFetching(true);
    // const formData = new FormData();
    // const file = evt.target;
    // formData.append('file', file as File);

    // const formData = evt.target;
    const formElement = document.getElementById('mainform') as HTMLFormElement;
    // if (formElement === null) {
    //   showToastMessage('Unable to get form element');
    //   return;
    // }
    const formData = new FormData(formElement);
    const file = evt.target[0].files[0];
    formData.append('file', file as File);

    const tokenresponse: AuthenticationResult | undefined = await getToken();
    if (tokenresponse === undefined) {
      showToastMessage('Unable to get an access token');
      return;
    }
    const endpoint = `${appConfig.apiEndpoint}/api/TextSentiment`;
    const bearer = `Bearer ${tokenresponse.accessToken}`;
    const headers = new Headers();
    headers.append('Authorization', bearer);
    // headers.append('Content-Type', 'multipart/form-data');
    headers.append('Ocp-Apim-Subscription-Key', `${appConfig.ocpApimSubscriptionKey}`);
    const options = {
      method: 'POST',
      headers,
      body: formData,
    };
    const result = await fetch(endpoint, options)
      .then(async (response) => {
        const data = await response.json();
        return data.content;
      })
      .catch((error) => {
        showToastMessage(`Fetching Text Sentiment failed with error:${error}`);
        return 'ERROR';
      });
    setFetching(false);
    if (result !== null) {
      setUploadResult(result);
    }

    const endpoint2 = `${appConfig.apiEndpoint}/api/SummarizeText/v2`;
    const result2 = await fetch(endpoint2, options)
      .then(async (response) => {
        const data = await response.json();
        return data.content;
      })
      .catch((error) => {
        showToastMessage(`Fetching Text Summary failed with error:${error}`);
        return 'ERROR';
      });
    setFetching(false);
    if (result !== null) {
      setSummaryResult(result2);
    }
  };

  const formcontent = () => {
    if (browserSize === BrowserSizeEnum.large) {
      return (
        <>
          <Form.Group controlId="fileuploadForm">
            <Form.Label>File Upload</Form.Label>
            <Form.Control type="file" onChange={fileSected} />
          </Form.Group>
          <Form.Group style={{ marginTop: '100px' }}>
            <Form.Label>Text to Analyze</Form.Label>
            <Form.Control as="textarea" rows={5} name="textblock" />
          </Form.Group>
          <Button variant="primary" type="submit" style={{ margin: 20 }}>Submit</Button>
          <div>
            { uploadResult !== '' ? <h4>Sentiment</h4> : null }
            <output form="uploadForm" name="sentiment">{uploadResult}</output>
          </div>
          <div>
            { summaryResult !== '' && <h4>Summary</h4> }
            <output form="uploadForm" name="summary">{summaryResult}</output>
          </div>
        </>
      );
    }
    return (
      <>
        <Form.Group controlId="fileuploadForm">
          <Form.Label style={{ margin: '5em' }}>File Upload</Form.Label>
          <Form.Control type="file" onChange={fileSected} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Text to Analyze</Form.Label>
          <textarea rows={10} cols={100} name="textblock" />
        </Form.Group>
        <Button variant="primary" type="submit" style={{ margin: 20 }}>Submit</Button>
        <div>
          <h4>Sentiment</h4>
          <output form="uploadForm" name="sentiment">{uploadResult}</output>
        </div>
        <div>
          <h4>Summary</h4>
          <output form="uploadForm" name="summary">{summaryResult}</output>
        </div>
      </>
    );
  };

  return (
    <>
      <h3>Sentiment Analysis</h3>
      <h4 style={{ textAlign: 'center', color: 'blue' }}>Upload a file or enter some text, and get back a sentiment analysis</h4>
      {fetching === true ? <SpinnerX /> : null}
      <Form id="mainform" encType="multipart/form-data" onSubmit={handleSubmit}>
        {formcontent()}
      </Form>
    </>
  );
}
